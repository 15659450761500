<template>
  <div class="container" style="width:97%;">
    <div class="filter-container">
      <el-button
          class="filter-item"
          type="primary"
          size="mini"
          icon="el-icon-search"
          style="margin-bottom:10px"
          @click="handleFind"
      >查询
      </el-button>
      <el-button
          class="filter-item"
          type="info"
          style="background: orange;border:none;"
          size="mini"
          icon="el-icon-s-home"
          @click="btnUpdatePosition()"
      >批量上架
      </el-button>
    </div>
    <el-table
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
        @selection-change="mulSelect"
    >
      <el-table-column type="selection" fixed="left" width="50"/>
      <el-table-column label="批号" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrLotNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="正题名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrPositiveTitle }}</span>
        </template>
      </el-table-column>
      <el-table-column label="书屋" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrLibraryName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="书架" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrBookshelfName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="第一责任者" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrFirstResponsible }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图书条码" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrBookBarcode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="ISBN" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrISBN }}</span>
        </template>
      </el-table-column>
      <el-table-column label="分类名" align="center">
        <template v-slot="{row}">
          <span>{{ row.StrCategoryName }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="操作"
          align="center"
          class-name="small-padding fixed-width"
          fixed="right">
        <template v-slot="{row}">
          <el-button type="info" size="small" v-if="row.OnShelf" @click="bookOffShelf(row.StrID)">下架</el-button>
          <el-button type="primary" size="small" @click="btnUpdatePosition(row, 'true')" v-else>上架</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div style="margin-top:50px;text-align:right;">
      <el-pagination
          background
          :total="total"
          :current-page="formModel.Page"
          :page-size="formModel.Perpage"
          :page-sizes="[10, 100,500,1000]"
          layout="prev,pager,next,jumper,sizes,total"
          @size-change="pageSizeChange"
          @current-change="currPageChange"
      />
    </div>

    <el-dialog width="30%" title="上架图书查询" v-model="dialogFormVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :model="formModel"
          label-position="left"
          label-width="80px"
          style="margin-left:50px;"
          size="small"
      >
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="图书条码">
              <el-input v-model="formModel.Search.StrBookBarcode" style="width: 210px"/>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="批号">
              <el-select v-model="formModel.Search.StrLotNumber" class="filter-item" clearable placeholder="请选择"
                         filterable>
                <el-option v-for="item in phList" :key="item.StrID" :label="item.StrLotNumber"
                           :value="item.StrLotNumber"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="书架">
              <el-select v-model="formModel.Search.StrBookshelfID" class="filter-item" clearable placeholder="请选择"
                         filterable>
                <el-option v-for="item in listShelf" :key="item.StrID" :label="item.StrLibraryName+' '+item.StrName"
                           :value="item.StrID"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="排序方式">
              <el-select v-model="formModel.OrderBy" class="filter-item" placeholder="请选择">
                <el-option v-for="item in pxOptions" :key="item.value" :label="item.name" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="查询内容" prop="title">
              <el-radio v-model="formModel.intNotOn" label="2">全部</el-radio>
              <el-radio v-model="formModel.intNotOn" label="1">已上架</el-radio>
              <el-radio v-model="formModel.intNotOn" label="0">未上架</el-radio>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="查询方式" prop="title">
              <el-radio v-model="formModel.Sort" label="1">升序</el-radio>
              <el-radio v-model="formModel.Sort" label="-1">降序</el-radio>
            </el-form-item>
          </el-col>

        </el-row>
      </el-form>
      <div name="footer" class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogFormVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="getList()">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
    <el-dialog title="上架" width="30%" v-model="dialogSJVisible" :close-on-click-modal="false">
      <el-form
          ref="dataForm"
          :model="formSJModel"
          label-position="left"
          label-width="100px"
          style="margin-left:50px;"
          size="small"
          :rules="rules"
      >
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="书屋" prop="StrLibrary">
              <el-select v-model="formSJModel.StrLibraryID" placeholder="请选择" @change="swChange">
                <el-option
                    v-for="item in swList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item label="书架" prop="strBookshelf">
              <el-select v-model="formSJModel.StrBookshelfID" placeholder="请选择">
                <el-option
                    v-for="item in sjList"
                    :key="item.StrID"
                    :label="item.StrName"
                    :value="item.StrID"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="dialog-footer" style="float: right;border: 10px">
        <el-button size="mini" @click="dialogSJVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="btnUpdatePosition">确定</el-button>
      </div>
      <p style="color: #ffffff">三阅</p>
    </el-dialog>
  </div>

</template>

<script>
export default {
  name: 'comtable',
  inject: ['reload'],
  data() {
    return {
      list: null,
      total: 0,
      listLoading: false,
      dialogFormVisible: false,
      textMap: {
        update: '编辑',
        create: '上架图书查询'
      },
      formModel: {
        Page: 1,
        Perpage: 10,
        Sort: "-1",
        intNotOn: "2", //需要默认值为0
        Search: {}
      },
      listShelf: [],
      listQuery: {
        Page: 1,
        Perpage: 1000,
        intNotOn: "1",
        Sort: "-1",
        OrderBy: "DTCreatedDate",
      },
      formSJModel: {},
      phList: [],
      pxOptions: [
        {
          value: 'StrBookBarcode',
          name: '图书条码'
        },
        {
          value: 'StrPublicationDate',
          name: '出版日期'
        }
      ],
      dialogSJVisible: false,
      sjList: [],
      swList: [],
      rules: {
        StrCollectionLocationID: [
          {required: true, message: '请选择书屋', trigger: 'change'}
        ],
        StrBookshelfID: [
          {required: true, message: '请选择书架', trigger: 'change'}
        ]
      },
      sjLists: [],
      selectRows: []
    }
  },
  created() {
    this.getPHList();
    this.getSWList();
    this.getShelfList()
    this.getList();
  },
  methods: {
    // 多选
    mulSelect(rows) {
      this.selectRows = rows
    },
    // 获取书屋
    getSWList() {
      this.$axios({
        url: this.localpath + 'librarys',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.swList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 根据书屋获取书架
    getSJList(val) {
      const param = {
        StrLibraryID: val
      }
      this.$axios({
        url: this.localpath + 'bookshelfs',
        method: 'post',
        data: JSON.stringify(param),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.sjList = res.data.msg
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 获取书架列表
    getShelfList() {
      this.$axios({
        url: this.localpath + 'bookshelfs/search',
        method: 'post',
        data: JSON.stringify(this.listQuery),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.listShelf = res.data.msg
          // this.total = res.data.total
          // this.listLoading = false
        }
      })
    },
    // 获取批号
    getPHList() {
      this.$axios({
        url: this.localpath + 'lotnumbers',
        method: 'get',
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then((res) => {
        if (res.data.ok) {
          this.phList = res.data.msg
          console.log(this.sjLists)
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 根据书屋名称查id
    swChange(val) {
      this.swList.forEach(item => {
        if (val === item.StrName) {
          this.formSJModel.StrLibraryID = item.StrID
        }
      })
      this.getSJList(this.formSJModel.StrLibraryID)
    },
    //图书下架
    bookOffShelf(bookID) {
      this.$axios({
        url: this.localpath + 'books/offshelf',
        method: 'post',
        data: JSON.stringify({StrBookID: bookID}),
        //发送格式为json
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.$message({
            message: '图书下架成功',
            type: 'success'
          })
          this.getList()
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    },
    // 上架
    btnUpdatePosition(row, boolean) {
      if (!this.dialogSJVisible) {
        this.dialogSJVisible = true
        if (boolean) {
          this.formSJModel.StrBookID = row.StrID
        } else {
          const strAarr = []
          this.selectRows.forEach(item => {
            strAarr.push(item.StrID)
          })
          this.formSJModel.StrBookID = strAarr.join(',')
        }
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
        })
      } else {
        this.formSJModel.OnSelf = true
        this.$refs['dataForm'].validate(valid => {
          if (valid) {
            this.$axios({
              url: this.localpath + 'books/onshelf',
              method: 'post',
              data: JSON.stringify(this.formSJModel),
              //发送格式为json
              headers:
                  {
                    'Content-Type': 'application/json',
                    'dataType': 'json',
                    'Authorization': localStorage.getItem("token")
                  }
            }).then(res => {
              if (res.data.ok) {
                this.$message({
                  message: '图书上架成功',
                  type: 'success'
                })
                this.getList()
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'error'
                })
              }
            })
            this.formSJModel = {}
            this.dialogSJVisible = false
          }
        })
      }
    },
    //图书查询
    getList() {
      this.dialogFormVisible = false
      this.listLoading = true
      // if (this.formModel.SearchValue != undefined) {
      //   this.formModel.Search["StrLotNumber"] = this.formModel.SearchValue
      // }
      if (this.formModel.intNotOn == "0") {
        this.formModel.Search["OnShelf"] = false
      } else if (this.formModel.intNotOn == "1") {
        this.formModel.Search["OnShelf"] = true
      } else {
        //全部
        //刪除onshelf字段
        delete this.formModel.Search.OnShelf
      }
      if (this.formModel.Search.StrBookBarcode !== undefined && this.formModel.Search.StrBookBarcode === "") {
        delete this.formModel.Search.StrBookBarcode
      }
      if (this.formModel.Search.StrBookshelfID !== undefined && this.formModel.Search.StrBookshelfID === "") {
        delete this.formModel.Search.StrBookshelfID
      }
      if (this.formModel.Search.StrLotNumber !== undefined && this.formModel.Search.StrLotNumber === "") {
        delete this.formModel.Search.StrLotNumber
      }
      //不能直接赋值，需要创建一个副本，否则在查询的时候排序方式会出现DTCreatedDate字段
      var formModel2 = JSON.parse(JSON.stringify(this.formModel));
      if (formModel2.OrderBy == undefined) {
        formModel2.OrderBy = "DTCreatedDate"
      }
      this.$axios({
        url: this.localpath + 'books/search',
        method: 'post',
        //发送格式为json
        data: JSON.stringify(formModel2),
        headers:
            {
              'Content-Type': 'application/json',
              'dataType': 'json',
              'Authorization': localStorage.getItem("token")
            }
      }).then(res => {
        if (res.data.ok) {
          this.list = res.data.msg
          this.total = res.data.total
          this.$message({
            message: '图书信息已获取',
            type: 'success'
          })
          this.listLoading = false
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }

      })
    },
    // 查询
    handleFind() {

      this.dialogFormVisible = true
    },
    currPageChange(Page) {
      this.formModel.Page = Page
      this.getList()
    },
    pageSizeChange(Perpage) {
      this.formModel.Perpage = Perpage
      this.getList()
    }
  },


};
</script>

<style scoped>
.slot .el-button {

}
</style>
